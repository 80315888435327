import React from 'react';
import { Row, Col, AntdLayout, Card, Typography } from '@pankod/refine-antd';

import styles from './login.module.scss';
import { Entry } from '../../components/Entry';

const { Title } = Typography;

export const Login: React.FC = () => {
    const CardTitle = (
        <>
            <Title level={3} className={styles.title}>
                <img src="/images/logo.png" alt="" width={98} height={96} />
                <span className={styles.titleContent}>
                    Державне пiдприємство
                    <br />
                    Державний експертний центр
                    <br />
                    Мiнiстерства охорони здоров'я України
                    <br />
                </span>
            </Title>
            <div className={styles.horLine}></div>
        </>
    );

    return (
        <AntdLayout className={styles.layout}>
            <Row
                justify="center"
                align="middle"
                style={{
                    height: '100vh',
                }}
            >
                <Col xs={22}>
                    <div className={styles.container}>
                        <Card
                            title={CardTitle}
                            headStyle={{ borderBottom: 0 }}
                            className={styles.contentBlock}
                        >
                            <Entry />
                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};
