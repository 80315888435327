import {
    useTranslate,
    IResourceComponentsProps,
    HttpError,
    CrudFilters,
} from '@pankod/refine-core';
import {
    List,
    Table,
    TextField,
    useTable,
    EditButton,
    TagField,
    Col,
    Row,
    Card,
    CreateButton,
} from '@pankod/refine-antd';
import { IPostFilterVariables, IApplication } from 'interfaces';

export const DashboardList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const { tableProps, searchFormProps } = useTable<
        IApplication,
        HttpError,
        IPostFilterVariables
    >({
        onSearch: params => {
            const filters: CrudFilters = [];
            const { status_id, type_id, query } = params;

            filters.push(
                {
                    field: 'query',
                    operator: 'eq',
                    value: query,
                },
                {
                    field: 'status_id',
                    operator: 'eq',
                    value: status_id,
                },
                {
                    field: 'type_id',
                    operator: 'eq',
                    value: type_id,
                }
            );

            return filters;
        },
        resource: 'dashboard',
    });

    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <List
                    pageHeaderProps={{
                        extra: [
                            <CreateButton>
                                {t('dashboards.buttons.create')}
                            </CreateButton>,
                        ],
                        title: t('labels.dashboard_editor'),
                    }}
                >
                    <Table
                        {...tableProps}
                        rowKey="id"
                        pagination={{
                            ...tableProps.pagination,
                        }}
                        scroll={{ x: 500 }}
                        bordered
                    >
                        <Table.Column
                            dataIndex="name"
                            key="name"
                            title={t('dashboards.fields.name')}
                            render={value => <TextField value={value} />}
                            width={300}
                        />
                        <Table.Column
                            dataIndex="active"
                            key="active"
                            title={t('dashboards.fields.active')}
                            render={value => {
                                function yesOrNo(value) {
                                    if (value) {
                                        return 'так';
                                    } else {
                                        return 'ні';
                                    }
                                }

                                let result = yesOrNo(value);

                                return <TextField value={result} />;
                            }}
                            sorter
                            width={300}
                        />
                        <Table.Column
                            dataIndex="views_count"
                            key="views_count"
                            title={t('dashboards.fields.usage')}
                            render={value => {
                                return <TextField value={value} />;
                            }}
                            width={300}
                        />
                        <Table.Column
                            dataIndex="category"
                            key="category"
                            title={t('dashboards.fields.category')}
                            render={value => value.name}
                            sorter
                            width={300}
                        />
                        <Table.Column
                            title={t('table.actions')}
                            dataIndex="actions"
                            render={(_, record: any) => {
                                let itemId = record.id;

                                return (
                                    <EditButton
                                        hideText
                                        size="small"
                                        recordItemId={itemId}
                                    />
                                );
                            }}
                        />
                    </Table>
                </List>
            </Col>
        </Row>
    );
};
