import {
    useTranslate,
    IResourceComponentsProps,
    useNotification,
    useNavigation,
} from '@pankod/refine-core';
import {
    Edit,
    Form,
    ListButton,
    useForm,
    Col,
    Row,
    Input,
    TreeSelect,
    Checkbox,
    useSelect,
} from '@pankod/refine-antd';

import { useParams } from 'react-router-dom';
import { api } from '../../utils/api';
import { ICategory } from '../../interfaces';
import { useMemo } from 'react';
import React from 'react';
import { TranslatedBreadcrumbs } from '../../components/TranslatedBreadcrumbs';
import { processArray } from '../category';
import hydrateVErrors from '../../utils/validate';

type Status = {
    id: number;
    code: string;
    name: string;
};

interface ECTDValidation {
    status: string;
    code: string;
    category: string;
    comment: string;
    criteria: string;
}

interface Structure {
    title: string;
    file_name: string;
    ectd_path: string;
    operation: string;
    uploaded_at: string | null;
    upload_code: number | null;
    upload_text: string | null;
}

export type Submission = {
    created_at: string;
    sequence: string;
    ectd_validation: ECTDValidation[];
    structure: Structure[];
    status: Status;
};

type PrintHistory = {
    code: string;
    name: string;
    created: string;
    id: number;
    url: string;
};

export const DashboardEdit: React.FC<IResourceComponentsProps> = () => {
    const { open: notifyOpen } = useNotification();
    const { id } = useParams();
    const t = useTranslate();
    const { replace } = useNavigation();

    const errorManipulation = (error: any[]) => {
        return error?.map((i: string) => {
            let [article, message, ...rest] = i.split(' ');
            let text = rest.join(' ');
            return t(`${article} ${message.split('.').at(-1)} ${text}`);
        });
    };

    const { formProps, saveButtonProps, form } = useForm<any>({
        onMutationSuccess: ({ data }, variables, context) => {
            form.resetFields();
            setTimeout(() => (window.location.href = `/categories`), 1000);
        },
        onMutationError: error => {
            const validationErrors = hydrateVErrors(error);

            // eslint-disable-next-line array-callback-return
            let errFields = Object.keys(validationErrors).map((key: string) => {
                let error = validationErrors?.[key];
                let messages = errorManipulation(error);

                return {
                    name: key
                        .split('.')
                        .map(i => (Number.isInteger(+i) ? +i : i)),
                    errors: [messages],
                };
            });

            //@ts-ignore
            form.setFields(errFields);
        },
        successNotification: {
            message: t('notifications.success'),
            type: 'success',
        },
        resource: 'dashboard',
        id,
        action: 'edit',
        redirect: false,
        queryOptions: {
            retry: false,
        },
    });

    const { selectProps, queryResult } = useSelect<ICategory>({
        resource: 'category',
        defaultValue: [],
        optionLabel: 'name',
        optionValue: 'id',
        metaData: {},
    });

    const processOptions = processArray(queryResult?.data?.data);

    const handleChange = (newValue: string) => {};

    const handleSubmit = async values => {
        let result = await api.put(`/api/dashboard/${id}`, values);

        if (result) {
            if (notifyOpen) {
                notifyOpen({
                    message: t('notifications.success'),
                    type: 'success',
                });
            }
        }
    };

    const initialObject = formProps.initialValues!;

    const newInitial = useMemo(
        () => ({
            active: initialObject?.active,
            category_id: initialObject?.category?.id,
            code: initialObject?.code,
            description: initialObject?.description,
            name: initialObject?.name,
            uuid: initialObject?.uuid,
        }),
        [initialObject]
    );

    return (
        <Edit
            saveButtonProps={{
                ...saveButtonProps,
            }}
            pageHeaderProps={{
                extra: [<ListButton key="listBtnKey" />],
                breadcrumb: <TranslatedBreadcrumbs />,
                title: t('dashboards.titles.edit_dashboard'),
                onBack: () => replace('/dashboards'),
            }}
        >
            <Form
                {...formProps}
                initialValues={newInitial}
                layout="vertical"
                onFinish={handleSubmit}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('dashboards.fields.name')}
                            name="name"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('dashboards.fields.category')}
                            name="category_id"
                        >
                            <TreeSelect
                                style={{ width: '100%' }}
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                }}
                                treeData={processOptions}
                                placeholder="Please select"
                                treeDefaultExpandAll
                                onChange={handleChange}
                                fieldNames={{
                                    label: 'title',
                                    value: 'id',
                                    children: 'children',
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('dashboards.fields.dashboard_code')}
                            name="code"
                        >
                            <Input readOnly />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('dashboards.fields.active')}
                            name="active"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('dashboards.fields.dashboard_uuid')}
                            name="uuid"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('dashboards.fields.description')}
                            name="description"
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
