import {
    Button,
    Col,
    Input,
    List,
    Modal,
    Popconfirm,
    Row,
    Spin,
    Tree,
    TreeProps,
} from '@pankod/refine-antd';
import type { DataNode } from 'antd/es/tree';
import { useEffect, useMemo, useState } from 'react';
import { api } from '../../utils/api';
import cn from 'classnames';

import s from './category.module.scss';
import {
    CheckOutlined,
    DeleteOutlined,
    EditOutlined,
    SaveOutlined,
} from '@ant-design/icons';
import {
    CustomResponse,
    useCustom,
    useNotification,
    useTranslate,
} from '@pankod/refine-core';

export interface Category {
    id?: number;
    name?: string;
    _lft?: number;
    _rgt?: number;
    parent_id?: any;
    created_at?: string;
    updated_at?: string;
    children?: Category[];
}

export interface CategoryResponse {
    data: {
        data?: Category[];
    };
}

interface ITreeElement {
    id: number | null;
    name: string | '';
}

const { TreeNode } = Tree;

export const processArray = <T,>(array: T[] | undefined) => {
    if (!array?.length) {
        return [];
    }

    return array.map((item: Category) => {
        if (item.children!.length) {
            return {
                ...item,
                title: item?.name,
                key: item?.id,
                children: processArray(item.children!),
            };
        }

        return {
            ...item,
            title: item.name,
            key: item.id,
            children: [],
        };
    });
};

const TreeComponent = ({ hierarchy, ...props }) => {
    const renderTreeNodes = data =>
        data.map(item => {
            if (item.children) {
                return (
                    <TreeNode
                        title={item.title}
                        key={item.key}
                        // @ts-ignore
                        dataRef={item}
                    >
                        {renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode key={item.key} {...item} {...props} />;
        });

    return (
        <Tree {...props} treeData={hierarchy}>
            {renderTreeNodes(hierarchy)}
        </Tree>
    );
};

const defaultData: DataNode[] = [];

export const CategoryList = () => {
    const t = useTranslate();
    const { open: notifyOpen } = useNotification();

    // tree state
    const [editElement, setEditElement] = useState<ITreeElement>({
        id: null,
        name: '',
    });

    const [treeData, setTreeData] = useState<any>(defaultData);
    const [expandedKeys, setExpandedKeys] = useState([]);

    const [renameModal, setRenameModal] = useState(false);
    const token = localStorage.getItem('token');

    const {
        data: categories,
        isLoading,
        refetch,
        isFetched,
        isRefetching,
    } = useCustom({
        url: `/api/category`,
        method: 'get',
        config: {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
        queryOptions: {
            select: ({ data }: any): CategoryResponse => {
                const payload = data?.data;

                return processArray(payload);
            },
        },
    });

    useEffect(() => {
        if (isFetched) {
            setTreeData(categories);
        }
    }, [isFetched, isRefetching]);

    const onSelect = (selectedKeys: React.Key[], info: any) => {
        setEditElement({
            id: +selectedKeys[0],
            name: info?.node?.title,
        });
    };

    const onDragEnter: TreeProps['onDragEnter'] = info => {};

    const onDrop: TreeProps['onDrop'] = info => {
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition =
            info.dropPosition - Number(dropPos[dropPos.length - 1]);

        const loop = (
            data: DataNode[],
            key: React.Key,
            callback: (node: DataNode, i: number, data: DataNode[]) => void
        ) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children!, key, callback);
                }
            }
        };
        const data = [...treeData];

        // Find dragObject
        let dragObj: DataNode;
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        if (!info.dropToGap) {
            // Drop on the content
            loop(data, dropKey, item => {
                item.children = item.children || [];
                // where to insert 示例添加到头部，可以是随意位置
                item.children.unshift(dragObj);
            });
        } else if (
            ((info.node as any).props.children || []).length > 0 && // Has children
            (info.node as any).props.expanded && // Is expanded
            dropPosition === 1 // On the bottom gap
        ) {
            loop(data, dropKey, item => {
                item.children = item.children || [];
                // where to insert 示例添加到头部，可以是随意位置
                item.children.unshift(dragObj);
                // in previous version, we use item.children.push(dragObj) to insert the
                // item to the tail of the children
            });
        } else {
            let ar: DataNode[] = [];
            let i: number;
            loop(data, dropKey, (_item, index, arr) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                ar.splice(i!, 0, dragObj!);
            } else {
                ar.splice(i! + 1, 0, dragObj!);
            }
        }

        setTreeData(data);
    };

    const handleUpdate = async data => {
        if (!data) return;

        let success = await api.put(`/api/category`, {
            data: data,
        });

        if (success) {
            if (notifyOpen) {
                notifyOpen({
                    message: t('notifications.success'),
                    type: 'success',
                });

                setTimeout(() => refetch(), 800);
            }
        }
    };

    const handleDelete = async (e, nodeId) => {
        e.preventDefault();

        if (nodeId) {
            await api.delete(`/api/category/${nodeId}`);
            await refetch();
        }
    };

    const handleRename = async (value: string, nodeId: number) => {
        if (value) {
            await api.post(`/api/category/${nodeId}`, {
                name: value,
            });
            await refetch();
            setEditElement({
                name: '',
                id: null,
            });
            setRenameModal(false);
        }
    };

    let memoLoader = useMemo(
        () => isLoading || isRefetching,
        [isLoading, isRefetching]
    );

    return (
        <>
            <Spin spinning={memoLoader}>
                <Row gutter={[16, 16]}>
                    <Col lg={24} xs={24}>
                        <List title={t('labels.category_editor')}>
                            <div>
                                <TreeComponent
                                    hierarchy={treeData}
                                    showLine={true}
                                    showIcon={false}
                                    onSelect={onSelect}
                                    onDragEnter={onDragEnter}
                                    onDrop={onDrop}
                                    draggable
                                    blockNode
                                    className={cn('draggable-tree', {
                                        [s.treeBox]: true,
                                    })}
                                    titleRender={item => {
                                        const { title, key, dataRef, ...rest } =
                                            item;

                                        let count =
                                            dataRef?.dashboards_count ??
                                            rest.dashboards_count;

                                        return (
                                            <span className={s.titleContent}>
                                                <span
                                                    className={s.title}
                                                    onClick={() =>
                                                        setRenameModal(true)
                                                    }
                                                >
                                                    {title}
                                                </span>

                                                {count !== null ? (
                                                    <span
                                                        className={
                                                            s.dashboardsCount
                                                        }
                                                    >{`(${count})`}</span>
                                                ) : null}

                                                <Popconfirm
                                                    title="Are you sure to delete this task?"
                                                    onConfirm={e => {
                                                        handleDelete(e, key);
                                                    }}
                                                    onCancel={() => {}}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button
                                                        type="primary"
                                                        size="small"
                                                        style={{
                                                            marginLeft: 10,
                                                        }}
                                                        danger
                                                        icon={
                                                            <DeleteOutlined />
                                                        }
                                                    />
                                                </Popconfirm>
                                            </span>
                                        );
                                    }}
                                    onExpand={(expKeys, { expanded }) => {
                                        if (expanded) {
                                            setExpandedKeys(
                                                expKeys.map(exp => String(exp))
                                            );
                                        } else {
                                            setExpandedKeys([]);
                                        }
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button
                                    type="primary"
                                    onClick={() => handleUpdate(treeData)}
                                    icon={<SaveOutlined />}
                                >
                                    Оновити
                                </Button>
                            </div>
                        </List>
                    </Col>
                </Row>
            </Spin>

            <Modal
                visible={renameModal}
                onCancel={() => {
                    setRenameModal(false);
                }}
                onOk={() => {
                    if (editElement.name && editElement.id) {
                        handleRename(editElement.name, editElement.id);
                    }
                }}
            >
                <Row>
                    <Col xs={12}>
                        <Input
                            type="text"
                            value={editElement?.name}
                            onChange={e =>
                                setEditElement(prev => ({
                                    ...prev,
                                    name: e.target.value,
                                }))
                            }
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
