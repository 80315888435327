import React from 'react';
import { Refine } from '@pankod/refine-core';
import { ErrorComponent } from '@pankod/refine-antd';
import './styles/antd.less';
import { dataProvider } from 'provider/dataProvider';
import { notificationProvider } from 'provider/notificationProvider';
import { authProvider } from './authProvider';
import {
    Title,
    Header,
    Sider,
    Footer,
    Layout,
    OffLayoutArea,
} from 'components/layout';
import { useTranslation } from 'react-i18next';
import { Login } from 'pages/login';
import { api } from './utils/api';
import { TusClientProvider } from 'use-tus';
import { BetterHelmet } from '@limeyfy/react-seo';
import { usePageName } from './hook/usePageName';
import { useLocation } from 'react-use';
import { ConfigProvider } from 'antd';
import { CategoryCreate, CategoryList } from './pages/category';
import {
    DashboardCreate,
    DashboardEdit,
    DashboardList,
} from './pages/dashboards';
import { ActivityList } from './pages/activity';
import { PharmasolList, PharmasolShow } from './pages/pharmasol';
import { DashboardItem } from './components/DashboardItem';
import uk_UA from 'antd/es/locale/uk_UA';
import { useResourceMapping } from './hook/useResourceMapping';
import { Reports } from './components/Reports';
import routerProvider from '@pankod/refine-react-router-v6';

function App() {
    const { t, i18n } = useTranslation();

    const user = localStorage.getItem('user')
        ? localStorage.getItem('user')
        : undefined;
    const roles = user ? JSON.parse(user)?.roles : [];
    const userType =
        roles.includes('Expert') && roles.includes('adminBI')
            ? 'AdminBI'
            : roles.includes('Expert')
            ? 'Expert'
            : null;

    let defaultResource =
        userType === 'Expert'
            ? [
                  {
                      name: 'reports',
                      list: Reports,
                      options: { label: t('labels.reports') },
                  },
              ]
            : [
                  {
                      name: 'reports',
                      list: Reports,
                      options: { label: t('labels.reports') },
                  },
                  {
                      name: 'categories',
                      list: CategoryList,
                      create: CategoryCreate,
                      options: { label: t('labels.category_editor') },
                  },
                  {
                      name: 'dashboards',
                      list: DashboardList,
                      create: DashboardCreate,
                      edit: DashboardEdit,
                      options: { label: t('labels.dashboard_editor') },
                  },
                  {
                      name: 'activity',
                      list: ActivityList,
                      options: {
                          label: t('activity.activity'),
                      },
                  },
                  {
                      name: 'dictionaries',
                      list: PharmasolList,
                      // @ts-ignore
                      show: PharmasolShow,
                      options: {
                          label: t('pharmasol.dictionaries'),
                      },
                  },
              ];

    // @ts-ignore
    const resources = useResourceMapping(defaultResource, () => <Reports />);

    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    const { pathname } = useLocation();
    const { pageName } = usePageName(pathname);
    const title = `${t('labels.unified_office_of_the_applicant')}: ${pageName}`;

    let slicedPath = pathname?.split('/').slice(0, -1);
    let joined = slicedPath?.join('/');
    let innerRoute = {
        ...routerProvider,
    };

    if (slicedPath) {
        innerRoute.routes = [
            {
                element: <DashboardItem />,
                path: joined?.includes('reports') ? `${joined}/:id` : '',
                layout: true,
                caseSensitive: true,
            },
        ];
    }

    return (
        <TusClientProvider>
            <BetterHelmet title={title} subTitle="">
                <meta name="description" content={pageName} />
            </BetterHelmet>

            <ConfigProvider locale={uk_UA}>
                <Refine
                    ReadyPage={() => null}
                    notificationProvider={notificationProvider}
                    catchAll={<ErrorComponent />}
                    routerProvider={innerRoute}
                    dataProvider={dataProvider(api)}
                    authProvider={authProvider}
                    LoginPage={Login}
                    resources={resources}
                    Title={Title}
                    Header={Header}
                    Sider={Sider}
                    Footer={Footer}
                    Layout={Layout}
                    OffLayoutArea={OffLayoutArea}
                    i18nProvider={i18nProvider}
                    disableTelemetry={true}
                />
            </ConfigProvider>
        </TusClientProvider>
    );
}

export default App;
