export const strToDate = (value: string, separator = ' ') => {
    let dateString = value
        ?.split(separator)?.[0]
        .split('.')
        .reverse()
        .join('-');
    let timeString = value?.split(separator)?.[1];

    return new Date(`${dateString} ${timeString ?? ''}`);
};

export const translit = str => {
    let ru =
        'А-а-Б-б-В-в-Ґ-ґ-Г-г-Д-д-Е-е-Ё-ё-Є-є-Ж-ж-З-з-И-и-І-і-Ї-ї-Й-й-К-к-Л-л-М-м-Н-н-О-о-П-п-Р-р-С-с-Т-т-У-у-Ф-ф-Х-х-Ц-ц-Ч-ч-Ш-ш-Щ-щ-Ъ-ъ-Ы-ы-Ь-ь-Э-э-Ю-ю-Я-я'.split(
            '-'
        );
    let en =
        "A-a-B-b-V-v-G-g-G-g-D-d-E-e-E-e-E-e-ZH-zh-Z-z-I-i-I-i-I-i-J-j-K-k-L-l-M-m-N-n-O-o-P-p-R-r-S-s-T-t-U-u-F-f-H-h-TS-ts-CH-ch-SH-sh-SCH-sch-'-'-Y-y-'-'-E-e-YU-yu-YA-ya".split(
            '-'
        );
    let res = '';
    for (let i = 0, l = str.length; i < l; i++) {
        let s = str.charAt(i),
            n = ru.indexOf(s);
        if (n >= 0) {
            res += en[n];
        } else {
            res += s;
        }
    }
    return res;
};

export const generateUrl = str => {
    let url = str.replace(/[\s]+/gi, '-');
    url = translit(url);
    url = url.replace(/[^0-9a-z_\-]+/gi, '').toLowerCase();
    return url;
};

export const flatten = (data, parent, listComponent) => {
    const ListComponent = listComponent;
    return data.flatMap(element => {
        let latinized = generateUrl(element.name);

        return [
            {
                id: element.id,
                name: latinized,
                parentName: parent ?? element.parent_id,
                list: () => (
                    <ListComponent
                        dashboards={element?.dashboards}
                        title={element?.name}
                    />
                ),
                options: {
                    label: element?.name,
                    count: element?.dashboards_count,
                },
            },
            ...flatten(element.children, latinized, ListComponent),
        ];
    });
};
