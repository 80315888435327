import React from 'react';
import {
    IResourceComponentsProps,
    useNavigation,
    useTranslate,
} from '@pankod/refine-core';
import {
    Col,
    Create,
    Form,
    Input,
    Row,
    SaveButton,
    Space,
    TreeSelect,
    useForm,
    useSelect,
} from '@pankod/refine-antd';

import { ICategory } from 'interfaces';
import { TranslatedBreadcrumbs } from '../../components/TranslatedBreadcrumbs';
import hydrateVErrors from '../../utils/validate';
import { processArray } from './list';

export const CategoryCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { replace } = useNavigation();

    const errorManipulation = (error: any[]) => {
        return error?.map((i: string) => {
            let [article, message, ...rest] = i.split(' ');
            let text = rest.join(' ');
            return t(`${article} ${message.split('.').at(-1)} ${text}`);
        });
    };

    const { formProps, saveButtonProps, form } = useForm<any>({
        onMutationSuccess: ({ data }, variables, context) => {
            form.resetFields();
            setTimeout(() => (window.location.href = `/categories`), 1000);
        },
        onMutationError: error => {
            const validationErrors = hydrateVErrors(error);

            // eslint-disable-next-line array-callback-return
            let errFields = Object.keys(validationErrors).map((key: string) => {
                let error = validationErrors?.[key];
                let messages = errorManipulation(error);

                return {
                    name: key
                        .split('.')
                        .map(i => (Number.isInteger(+i) ? +i : i)),
                    errors: [messages],
                };
            });

            //@ts-ignore
            form.setFields(errFields);
        },
        successNotification: {
            message: t('notifications.success'),
            type: 'success',
        },
        resource: 'category',
        redirect: false,
    });

    const { selectProps, queryResult } = useSelect<ICategory>({
        resource: 'category',
        defaultValue: [],
        optionLabel: 'name',
        optionValue: 'id',
        metaData: {},
    });

    const processOptions = processArray(queryResult?.data?.data);

    const onChange = (newValue: string) => {};

    const saveButton = {
        ...saveButtonProps,
    };

    return (
        <Create
            actionButtons={
                <Space>
                    <SaveButton {...saveButton} />
                </Space>
            }
            pageHeaderProps={{
                title: t('categories.titles.create_category'),
                breadcrumb: <TranslatedBreadcrumbs />,
                onBack: () => replace('/categories'),
            }}
        >
            <Form {...formProps} layout="vertical" initialValues={{}}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('categories.fields.name')}
                            name="name"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('categories.fields.parent_id')}
                            name="parent_id"
                        >
                            <TreeSelect
                                style={{ width: '100%' }}
                                value={''}
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                }}
                                treeData={processOptions}
                                placeholder={t('labels.select')}
                                treeDefaultExpandAll
                                onChange={onChange}
                                fieldNames={{
                                    label: 'title',
                                    value: 'id',
                                    children: 'children',
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};
