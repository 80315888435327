import React from 'react';
import { Tabs } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import cn from 'classnames';

import { LoginForm } from 'components/Entry/LoginForm';
import { FileKey } from 'components/Entry/FileKey';
import { Token } from 'components/Entry/Token';

import styles from './Entry.module.scss';

export const Entry = () => {
    const { TabPane } = Tabs;
    const t = useTranslate();

    return (
        <>
            <Tabs defaultActiveKey="1">
                <TabPane tab={t('pages.login.password')} key="1">
                    <LoginForm />
                </TabPane>
                <TabPane tab={t('pages.login.file_key')} key="2">
                    <FileKey />
                </TabPane>
                <TabPane tab={t('pages.login.hardware_key')} key="3">
                    <Token />
                </TabPane>
            </Tabs>
            <div className={styles.footerInfo}>
                <span className={styles.footerRow}>Технічна підтримка</span>
                <span className={styles.footerRow}>
                    <span className={styles.footerLabel}>e-mail</span>
                    <a
                        href="mailto:eias@dec.gov.ua"
                        className={styles.footerLink}
                    >
                        eias@dec.gov.ua
                    </a>
                </span>
                <span className={styles.footerRow}>
                    tel. <a href="tel:+38-044-202-17-07">+38-044-202-17-07</a>
                </span>
            </div>
        </>
    );
};
