import React, { useEffect, useState } from 'react';

import { LayoutProps } from '@pankod/refine-core';
import { AntdLayout, Grid } from '@pankod/refine-antd';

import styles from './Layout.module.scss';

const getYear = () => {
    let date = new Date();

    return date.getFullYear();
};

export const Layout: React.FC<LayoutProps> = ({
    children,
    Header,
    Footer,
    OffLayoutArea,
}) => {
    const [year, setYear] = useState<number | null>(null);
    useEffect(() => {
        let y = getYear();
        setYear(y);
    }, []);

    const breakpoint = Grid.useBreakpoint();

    return (
        <AntdLayout className={styles.pageWrapper}>
            <AntdLayout className={styles.innerPageWrapper}>
                {Header && <Header />}
                <AntdLayout.Content>
                    <div
                        style={{
                            padding: breakpoint.sm ? 24 : 12,
                            minHeight: 360,
                        }}
                    >
                        <div
                            className="container"
                            style={{
                                width: '100%',
                            }}
                        >
                            {children}
                        </div>
                    </div>
                    {OffLayoutArea && <OffLayoutArea />}
                </AntdLayout.Content>

                {Footer && (
                    <Footer>
                        <div className="container">
                            © {year} Всі права захищені
                        </div>
                    </Footer>
                )}
            </AntdLayout>
        </AntdLayout>
    );
};
