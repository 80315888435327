import { useEffect, useMemo, useState } from 'react';
import { api } from '../utils/api';
import { flatten } from '../utils/accessory';
import i18next from 'i18next';
import { Dashboards } from '../components/layout/dashboards';
import { TOKEN_KEY, USER_KEY } from '../authProvider';
import { IResourceComponentsProps } from '@pankod/refine-core';

const AUTH_KEY = 'auth';

interface IResource {
    name: string;
    list?: React.FunctionComponent<IResourceComponentsProps<any, any>>;
    create?: React.FunctionComponent<IResourceComponentsProps<any, any>>;
    edit?: React.FunctionComponent<IResourceComponentsProps<any, any>>;
    options: object;
}

export const useResourceMapping = (
    predResources: IResource[],
    reportsComp?: () => JSX.Element
) => {
    const token = localStorage.getItem(AUTH_KEY);
    const [resources, setResources] = useState<IResource[]>([]);
    const Reports = reportsComp;

    const init = resource => {
        let newResource: any[] = [];

        newResource = newResource.concat(resource);

        return newResource;
    };

    const getResource = async (token, predResources) => {
        let { data } = await api.get(`/api/dashboard/all/tree`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        let payload = data?.data;

        let newResources = flatten(payload, 'reports', Dashboards);

        newResources = newResources.concat(predResources) ?? [];

        return newResources;
    };

    const getQuickAuthResource = async () => {
        try {
            let url = window.location;
            let search = url.search;
            let paramToken = search.split('token=')[1] ?? null;

            if (!paramToken) {
                return init(predResources);
            }

            let { data } = await api.post('/api/auth/login-tmp-token', {
                tmp_token: paramToken,
            });

            let accessToken = data?.access_token;
            let user = data?.user;

            if (user && accessToken) {
                localStorage.setItem(TOKEN_KEY, accessToken);
                localStorage.setItem(USER_KEY, JSON.stringify(user));

                return await getResource(accessToken, predResources);
            }
        } catch (error) {
            console.error(error);
            return init(predResources);
        }
    };

    useEffect(() => {
        const getData = async () => {
            if (!token) {
                let resource = await getQuickAuthResource();

                setResources(resource);
            } else {
                let resource = await getResource(token, predResources);

                setResources(resource);
            }
        };
        getData();
    }, [token]);

    return useMemo(() => resources ?? [], [resources]);
};
