export default function hydrateVErrors(exception: any) {
    try {
        let { data, status } = exception?.response;

        if (status === 422) {
            return data?.errors;
        }
    } catch (e) {
        return [];
    }
}
