import { FC, useEffect, useRef, useState } from 'react';
import { useDashboardData } from '../../../hook/useDashboardData';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import config from '../../../config/path';
import { Card, Col, List, Row, Spin } from '@pankod/refine-antd';
import routerProvider from '@pankod/refine-react-router-v6';
import dayjs from 'dayjs';
import { useTranslate } from '@pankod/refine-core';
import { EyeOutlined } from '@ant-design/icons';
import styles from './dashboards.module.scss';

export type Settings = {
    id: string;
    supersetDomain: string;
    mountPoint: HTMLDivElement;
    fetchGuestToken: () => Promise<string>;
    dashboardUiConfig: any;
};

interface IDashboard {
    id: number;
    name: string;
    uuid: string;
    code: string;
    order: string;
    category_id: number;
    active: boolean;
    description: string;
    last_view_datetime?: string | null;
    views_count: number;
    created_at: string | null;
    updated_at: string | null;
}

interface IDashboardsProps {
    dashboards: any[];
    title: string;
}

export const Dashboards: FC<IDashboardsProps> = ({ dashboards, title }) => {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const [dashboard, setDashboard] = useState<any>(null);
    const { pathname } = routerProvider.useLocation();
    const { push } = routerProvider.useHistory();
    const t = useTranslate();

    useEffect(() => {
        setDashboard(null);
    }, []);

    const { token, id: dashId, isLoading } = useDashboardData(dashboard, []);

    const initDashboard = async (settings: Settings) =>
        await embedDashboard(settings);

    const isValidSettings = (id, token) => !!id && !!token;

    const isValid = isValidSettings(dashId, token);

    useEffect(() => {
        if (isValid) {
            if (chartContainerRef.current) {
                initDashboard({
                    id: dashId,
                    supersetDomain: `${config.SUPERSET_API}`,
                    mountPoint: chartContainerRef.current,
                    fetchGuestToken: async () => token,
                    dashboardUiConfig: { hideTitle: true },
                }).then(res => res);
            }
        }
    }, [dashId, token]);

    const handleRender = (e, id) => {
        e.preventDefault();
        push(`${pathname}/${id}`);
    };

    return (
        <List
            pageHeaderProps={{
                title: title,
            }}
        >
            <Spin spinning={isLoading}>
                <Row gutter={[16, 16]}>
                    {dashboards?.map((dashboard: IDashboard) => {
                        return (
                            <Col xs={24} sm={12} lg={6} key={dashboard.id}>
                                <Card
                                    title={dashboard.name}
                                    extra={
                                        <a
                                            href=""
                                            onClick={e =>
                                                handleRender(e, dashboard.id)
                                            }
                                        >
                                            <EyeOutlined
                                                className={styles.icon}
                                            />
                                            {t('dashboards.titles.view')}
                                        </a>
                                    }
                                    className={styles.card}
                                >
                                    <p>
                                        <b>
                                            {t('dashboards.titles.description')}
                                            :
                                        </b>{' '}
                                        {dashboard?.description}
                                    </p>
                                    <p>
                                        <b>
                                            {t('dashboards.titles.views_count')}
                                            :
                                        </b>{' '}
                                        {dashboard?.views_count}
                                    </p>
                                    <p>
                                        <b>
                                            {t('dashboards.titles.created_at')}:
                                        </b>{' '}
                                        {dayjs(dashboard?.created_at).format(
                                            'DD.MM.YYYY'
                                        )}
                                    </p>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </Spin>
        </List>
    );
};
