import React, { useRef, useState } from 'react';
import { useLogin, useTranslate } from '@pankod/refine-core';
import { Button, Form, Input, Spin } from '@pankod/refine-antd';
import { UploadDragger } from '../UploadDragger';
import { getBase64 } from '../../utils/sign';
import axios from 'axios';
import type { InputRef } from 'antd';
import config from '../../config/path';

import { IFileKeyForm } from 'interfaces/auth';
import { Link } from 'react-router-dom';
import { BlockOutlined, FilePdfOutlined } from '@ant-design/icons';

import styles from './Entry.module.scss';

export const FileKey = () => {
    const t = useTranslate();
    const [form] = Form.useForm<IFileKeyForm>();
    const { mutate: login, isLoading: formIsLoading } = useLogin<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const passwordRef = useRef<InputRef>(null);

    const onSignViaKey = async (values: any) => {
        try {
            setIsLoading(true);

            const form = {
                file: values.file?.[0]?.originFileObj,
                password: values.password,
            };

            let b64String = await getBase64(form?.file);

            let clientResponse = await axios.post(
                `${config.CRYPTO_LIB_API}/api/Crypto/ClientHello`,
                {
                    PrivateKey: b64String,
                    Password: form.password,
                }
            );

            let clientIds = clientResponse.data.ClientIds;

            let proxyResponse = await axios.post(
                `${config.API}/api/crypto-proxy?proxy=InitialCryptoAuth`,
                {
                    clientIds,
                }
            );

            let proxyServerKeyExchange = proxyResponse?.data?.serverKeyExchange;
            let proxyClientCertificate = proxyResponse?.data?.clientCertificate;
            let proxyServerCertificate = proxyResponse?.data?.serverCertificate;

            let clientKeyResponse = await axios.post(
                `${config.CRYPTO_LIB_API}/api/Crypto/ClientKeyExchange`,
                {
                    ServerKeyExchange: proxyServerKeyExchange,
                    ClientCertificate: proxyClientCertificate,
                    ServerCertificate: proxyServerCertificate,
                    Password: form.password,
                    PrivateKey: b64String,
                }
            );

            let clientId = clientKeyResponse.data?.ClientId;
            let clientKeyExchange = clientKeyResponse.data?.ClientKeyExchange;
            let serverKeyExchange = clientKeyResponse.data?.ServerKeyExchange;

            login({
                ClientId: clientId,
                ClientKeyExchange: clientKeyExchange,
                ServerKeyExchange: serverKeyExchange,
                ClientCertificate: proxyClientCertificate,
            });

            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.error(e);
        }
    };

    const getFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }

        let fileList = e?.fileList;
        let finalList =
            fileList?.length > 1 ? [{ ...fileList.at(-1) }] : e.fileList;

        return e && finalList;
    };

    const handleElementFocus = (): void => {
        if (form.getFieldValue('file')) {
            passwordRef.current!.focus();
        }
    };

    const getErrorClassName = (fileList: string | null) => ({
        className: fileList ? '' : 'ant-upload-error',
        fileList,
    });

    return (
        <Spin spinning={isLoading || formIsLoading}>
            <Form<IFileKeyForm>
                layout="vertical"
                form={form}
                onFinish={onSignViaKey}
                requiredMark={false}
                initialValues={{
                    file: null,
                    password: '',
                }}
            >
                <Form.Item
                    name="file"
                    label={t('pages.login.file')}
                    rules={[
                        {
                            required: true,
                            message: t('required_field'),
                        },
                    ]}
                    valuePropName="fileList"
                    getValueFromEvent={getFile}
                    getValueProps={getErrorClassName}
                >
                    <UploadDragger
                        title={t('pages.login.add')}
                        onChange={handleElementFocus}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    label={t('pages.login.password')}
                    rules={[
                        {
                            required: true,
                            message: t('required_field'),
                        },
                    ]}
                >
                    <Input
                        type="password"
                        size="large"
                        placeholder={t('pages.login.password')}
                        ref={passwordRef}
                    />
                </Form.Item>
                <Form.Item style={{ marginBottom: 13 }}>
                    <Button type="primary" size="large" htmlType="submit" block>
                        {t('pages.login.signin')}
                    </Button>
                </Form.Item>
            </Form>
            <div className={styles.line}>
                <Link to="/cryptoprovider.exe" target="_blank">
                    <BlockOutlined /> {t('pages.login.install_crypto_library')}
                </Link>
            </div>
            <div className={styles.line}>
                <Link to="/crypto-instructions.pdf" target="_blank">
                    <FilePdfOutlined />{' '}
                    {t('pages.login.crypto_library_user_manual')}
                </Link>
            </div>
            {/*<div>*/}
            {/*    <Link to="/cabinet-dec-instructions.pdf" target="_blank">*/}
            {/*        <FilePdfOutlined />{' '}*/}
            {/*        {t('pages.login.user_manual_for_the_applicants_profile')}*/}
            {/*    </Link>*/}
            {/*</div>*/}
        </Spin>
    );
};
