const access = {
    expert: ['reports'],
    admin: ['reports', 'activity', 'dictionaries', 'dashboards', 'categories'],
};

const isShowItem = (name, roles) => {
    let userType = roles;

    if (roles.includes('adminBI') || userType?.[0] === 'adminBI') {
        return access.admin.includes(name);
    } else if (userType?.[0] === 'Expert') {
        return access.expert.includes(name);
    }
};

export { access, isShowItem };
