import { Button, Form, Input, Select, Spin } from '@pankod/refine-antd';
import React, { useEffect, useRef, useState } from 'react';
import { useLogin, useTranslate } from '@pankod/refine-core';
import config from '../../config/path';
import axios from 'axios';
import { ITokenForm, IToken } from '../../interfaces/auth';
import { InputRef } from 'antd';
import { Link } from 'react-router-dom';
import { BlockOutlined, FilePdfOutlined } from '@ant-design/icons';

import styles from './Entry.module.scss';

export const Token = () => {
    const [form] = Form.useForm<ITokenForm>();
    const [tokenList, setTokenList] = useState<any[]>([]);
    const [item, setItem] = useState<IToken | null>(null);
    const t = useTranslate();
    const { mutate: login, isLoading: formIsLoading } = useLogin<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const passwordRef = useRef<InputRef>(null);

    useEffect(() => {
        async function getTokens() {
            let data = await axios.get(
                `${config.CRYPTO_LIB_API}/api/crypto/getactivetokens`
            );

            setTokenList(data.data);
        }

        getTokens();
    }, []);

    const onSignViaToken = async (values: any) => {
        try {
            setIsLoading(true);

            const form = {
                file: values.file?.fileList?.[0]?.originFileObj,
                password: values.password,
            };

            let clientResponse = await axios.post(
                `${config.CRYPTO_LIB_API}/api/Crypto/ClientHello`,
                {
                    TokenLibraryCode: item?.LibraryCode,
                    TokenSerialNumber: item?.SerialNumber,
                    Password: form?.password,
                }
            );

            let clientIds = clientResponse.data.ClientIds;

            let proxyResponse = await axios.post(
                `${config.API}/api/crypto-proxy?proxy=InitialCryptoAuth`,
                {
                    clientIds,
                }
            );

            let proxyServerKeyExchange = proxyResponse.data.serverKeyExchange;
            let proxyClientCertificate = proxyResponse.data.clientCertificate;
            let proxyServerCertificate = proxyResponse.data.serverCertificate;

            let clientKeyResponse = await axios.post(
                `${config.CRYPTO_LIB_API}/api/Crypto/ClientKeyExchange`,
                {
                    ServerKeyExchange: proxyServerKeyExchange,
                    ClientCertificate: proxyClientCertificate,
                    ServerCertificate: proxyServerCertificate,
                    Password: form.password,
                    TokenLibraryCode: item?.LibraryCode,
                    TokenSerialNumber: item?.SerialNumber,
                }
            );

            let clientId = clientKeyResponse.data.ClientId;
            let clientKeyExchange = clientKeyResponse.data.ClientKeyExchange;
            let serverKeyExchange = clientKeyResponse.data.ServerKeyExchange;

            login({
                ClientId: clientId,
                ClientKeyExchange: clientKeyExchange,
                ServerKeyExchange: serverKeyExchange,
                ClientCertificate: proxyClientCertificate,
            });

            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.error(e);
        }
    };

    const handleElementFocus = (): void => {
        if (form.getFieldValue('token')) {
            passwordRef.current!.focus();
        }
    };

    const handleChangeToken = (value: string) => {
        let token = tokenList.find(i => i.SerialNumber === value);
        setItem(token);
        handleElementFocus();
    };

    return (
        <Spin spinning={isLoading || formIsLoading}>
            <Form form={form} onFinish={onSignViaToken} layout="vertical">
                <Form.Item
                    label={t('pages.login.token')}
                    name="token"
                    rules={[
                        {
                            required: true,
                            message: t('required_field'),
                        },
                    ]}
                >
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Виберіть токен зі списку"
                        onChange={handleChangeToken}
                        size="large"
                    >
                        {tokenList?.map(i => (
                            <Select.Option
                                value={i.SerialNumber}
                                key={i.SerialNumber}
                            >
                                {i.LibraryCode}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={t('pages.login.password')}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: t('required_field'),
                        },
                    ]}
                >
                    <Input
                        type="password"
                        placeholder={t('pages.login.password')}
                        size="large"
                        ref={passwordRef}
                    />
                </Form.Item>
                <Form.Item style={{ marginBottom: 13 }}>
                    <Button type="primary" size="large" htmlType="submit" block>
                        {t('pages.login.signin')}
                    </Button>
                </Form.Item>
            </Form>
            <div className={styles.line}>
                <Link to="/cryptoprovider.exe" target="_blank">
                    <BlockOutlined /> {t('pages.login.install_crypto_library')}
                </Link>
            </div>
            <div className={styles.line}>
                <Link to="/crypto-instructions.pdf" target="_blank">
                    <FilePdfOutlined />{' '}
                    {t('pages.login.crypto_library_user_manual')}
                </Link>
            </div>
            {/*<div>
                <Link to="/cabinet-dec-instructions.pdf" target="_blank">
                    <FilePdfOutlined />{' '}
                    {t('pages.login.user_manual_for_the_applicants_profile')}
                </Link>
            </div>*/}
        </Spin>
    );
};
