import React, { useState } from 'react';
import {
    CanAccess,
    ITreeMenu,
    useLogout,
    useRouterContext,
    useTranslate,
    useMenu,
} from '@pankod/refine-core';
import { Grid, Menu as AntMenu } from '@pankod/refine-antd';
import { LogoutOutlined, UnorderedListOutlined } from '@ant-design/icons';

import { isShowItem } from '../../../config/access';

import styles from './Menu.module.scss';

export const Menu: React.FC = () => {
    const [collapsed, setCollapsed] = useState<boolean>(false);

    const { mutate: logout } = useLogout();

    const { Link } = useRouterContext();
    const { SubMenu } = AntMenu;

    const translate = useTranslate();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu();

    const breakpoint = Grid.useBreakpoint();

    const user = localStorage.getItem('user')
        ? localStorage.getItem('user')
        : undefined;

    const roles = user ? JSON.parse(user)?.roles : [];

    const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
        let isTopLevel = tree.some(
            i => i.name === 'reports' || i.name === 'categories'
        );

        return tree.map((item: ITreeMenu) => {
            const { icon, label, route, name, children, parentName, options } =
                item;
            const count = options?.count;
            const formattedCount =
                count > 0 ? ' (' + options?.count + ') ' : '';

            if (!isShowItem(name, roles) && isTopLevel) {
                return;
            }

            if (children?.length > 0) {
                return (
                    <SubMenu
                        key={name}
                        // icon={icon ?? <UnorderedListOutlined />}
                        title={
                            label === 'Звіт' ? (
                                label + ' (' + children.length + ') '
                            ) : (
                                <Link to={route}>{label + formattedCount}</Link>
                            )
                        }
                    >
                        {renderTreeView(children, selectedKey)}
                    </SubMenu>
                );
            }
            const isSelected = route === selectedKey;
            const isRoute = !(
                parentName !== undefined && children?.length === 0
            );

            return (
                <CanAccess
                    key={route}
                    resource={name?.toLowerCase()}
                    action="list"
                >
                    <AntMenu.Item
                        key={selectedKey}
                        style={{
                            fontWeight: isSelected ? 'bold' : 'normal',
                        }}
                        // icon={icon ?? (isRoute && <UnorderedListOutlined />)}
                    >
                        <Link to={route}>{label + formattedCount} </Link>
                        {!collapsed && isSelected && (
                            <div className="ant-menu-tree-arrow" />
                        )}
                    </AntMenu.Item>
                </CanAccess>
            );
        });
    };

    return (
        <AntMenu
            selectedKeys={[]}
            defaultOpenKeys={defaultOpenKeys}
            mode="horizontal"
            onClick={() => {
                if (!breakpoint.lg) {
                    setCollapsed(true);
                }
            }}
            className={styles.headerMenu}
            selectable
        >
            {renderTreeView(menuItems, selectedKey)}

            <AntMenu.Item key="logout" onClick={() => logout()}>
                {translate('buttons.logout', 'Logout')}
            </AntMenu.Item>
        </AntMenu>
    );
};
