import { AntdLayout, Col, Row } from '@pankod/refine-antd';
import { Menu } from 'components/layout/menu';
import routerProvider from '@pankod/refine-react-router-v6';
import { USER_KEY } from '../../../authProvider';
import cn from 'classnames';

import styles from './Header.module.scss';

const { Link } = routerProvider;

export const Header: React.FC = () => {
    let user: string = localStorage.getItem(USER_KEY) ?? '';
    let { first_name: firstName } = user && JSON.parse(user);

    return (
        <AntdLayout.Header className={styles.header}>
            <div className="container">
                <Row style={{ width: '100%' }}>
                    <Col
                        className={cn(
                            styles.logoColumn,
                            'ant-col ant-col-xs-24 ant-col-lg-3'
                        )}
                    >
                        <div className={styles.logoContainer}>
                            <Link to="/">
                                <img
                                    src="/images/logo.png"
                                    alt="Державне підприємство
Державний експертний центр Міністерства охорони здоров'я України"
                                />
                            </Link>
                        </div>
                    </Col>
                    <Col
                        className={cn(
                            styles.textColumn,
                            'ant-col ant-col-xs-24 ant-col-lg-22'
                        )}
                    >
                        <Row>
                            <Col xs={24}>
                                <div className={styles.topText}>
                                    Державне підприємство
                                    <br />
                                    Державний експертний центр Міністерства
                                    охорони здоров'я України
                                </div>
                                <div className={styles.horLine}></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} lg={22} xl={20}>
                                <Menu />
                            </Col>
                            <Col xs={24} lg={2} xl={4}>
                                <div className={styles.userContent}>
                                    {firstName}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </AntdLayout.Header>
    );
};
