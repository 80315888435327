import React, { useEffect, useRef, useState } from 'react';
import { useDashboardData } from '../../hook/useDashboardData';
import { embedDashboard } from '@preset-sdk/embedded';
import config from '../../config/path';
import { List, Spin } from '@pankod/refine-antd';
import { Charts } from '../Charts';
import routerProvider from '@pankod/refine-react-router-v6';
import { TranslatedBreadcrumbs } from '../TranslatedBreadcrumbs';

const { useParams } = routerProvider;

export type Settings = {
    id: string;
    supersetDomain: string;
    mountPoint: HTMLDivElement;
    fetchGuestToken: () => Promise<string>;
    dashboardUiConfig: any;
};

export const DashboardItem = () => {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const [dashboard, setDashboard] = useState<any>(null);
    const { id } = useParams();
    const { token, id: dashId, isLoading } = useDashboardData(id, [id]);

    const initDashboard = async (settings: Settings) =>
        await embedDashboard(settings);

    const isValidSettings = (id, token) => !!id && !!token;

    const isValid = isValidSettings(dashId, token);

    useEffect(() => {
        if (isValid) {
            if (chartContainerRef.current) {
                if (dashId) {
                    initDashboard({
                        id: dashId,
                        supersetDomain: `${config.SUPERSET_API}`,
                        mountPoint: chartContainerRef.current,
                        fetchGuestToken: async () => token,
                        dashboardUiConfig: {
                            hideTitle: true,
                        },
                    }).then(res => res);
                }
            }
        }
    }, [dashId, token, dashboard]);

    return (
        <Spin spinning={isLoading}>
            <List
                pageHeaderProps={{
                    breadcrumb: <TranslatedBreadcrumbs />,
                }}
            >
                <Charts id="chart-container" ref={chartContainerRef} />
            </List>
        </Spin>
    );
};
