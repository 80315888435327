import process from 'process';

const config = {
    API: process.env.REACT_APP_API ?? '',
    SUPERSET_API: process.env.REACT_APP_SUPERSET_API ?? '',
    CRYPTO_LIB_API: process.env.REACT_APP_CRYPTO_LIB_API ?? '',
    WORKFLOW_API: process.env.REACT_APP_WORKFLOW_API ?? '',
};

export default config;
