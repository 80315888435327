import { forwardRef } from 'react';
import { Buffer } from 'buffer';
import s from './Charts.module.scss';

// @ts-ignore
window.Buffer = Buffer;

export type Ref = HTMLDivElement;

interface ChartsProps {
    id: string;
    [key: string]: any;
}

export const Charts = forwardRef<Ref, ChartsProps>((props, ref) => {
    return <div ref={ref} className={s.charts} {...props} />;
});
