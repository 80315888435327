import { api } from '../utils/api';
import config from '../config/path';
import { useEffect, useMemo, useState } from 'react';

export const useDashboardData = (id: string, dependencies: any[] = []) => {
    const [data, setData] = useState({
        token: '',
        id: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getData = async () => {
            try {
                if (id === null || id === 'undefined') {
                    setData({
                        token: '',
                        id: '',
                    });
                    setIsLoading(false);
                    return;
                }

                setIsLoading(true);
                let token = localStorage.getItem('auth');
                let reqConfig = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };

                let { data } = await api.get(
                    `${config.API}/api/dashboard/${id}/init`,
                    reqConfig
                );

                setData({
                    token: data?.token,
                    id: data?.id,
                });
                setIsLoading(false);
            } catch (e) {
                console.error(e);
                setData({
                    token: '',
                    id: '',
                });
                setIsLoading(false);
            }
        };
        getData();
    }, dependencies);

    const payload = useMemo(
        () => ({
            token: data.token,
            id: data.id,
            isLoading: isLoading,
        }),
        [data, isLoading]
    );

    return payload;
};
