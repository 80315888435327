import { useMemo } from 'react';
import i18n from 'i18next';

export const isExistTranslation = key => i18n.exists(key);

export const usePageName = (deps: any) => {
    const pagePath = window.location.pathname;
    const replaced = pagePath.split('/').filter(i => i);
    const assembledStr =
        replaced.length > 1 ? replaced.join('-') : replaced.join(' ');

    let processedStr =
        Array.isArray(assembledStr.split('-')) &&
        assembledStr.split('-').length > 1
            ? assembledStr
                  .split('-')
                  .filter((i: string) => isNaN(Number(i)))
                  .map((i: string) => {
                      let value = i;

                      if (isExistTranslation(`labels.${i}`)) {
                          value = i18n.t(`labels.${i}`);
                      }

                      return value;
                  })
                  .join('-')
            : i18n.t(`labels.${assembledStr}`);

    const pageName = useMemo(
        () => processedStr,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [i18n, pagePath, deps, assembledStr]
    );

    return {
        pageName,
    };
};
