import { useNavigate } from 'react-router-dom';
import { FC, useEffect } from 'react';
import { useTranslate } from '@pankod/refine-core';

interface IReportsProps {
    to?: string;
}

export const Reports: FC<IReportsProps> = ({ to }) => {
    const navigate = useNavigate();
    const t = useTranslate();

    useEffect(() => {
        if (to) {
            navigate(to);
        }
    }, [to]);

    return <h1>{t('labels.reports')}</h1>;
};
